.header {
  display: flex;
  align-self: center;
  justify-content: stretch;
  padding: 25px;
}

.logo {
  margin: 0;
}

.nav {
  flex-grow: 1;
  margin: 0 16px;
}

.navList {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}