.item {
  margin: 0 12px;
}

.link {
  text-decoration: none;
  color: black;
}

.link:hover {
  text-decoration: underline;
}